@font-face {
  font-family: 'National';
  font-weight: 200;
  font-style: normal;
  src: url('./assets/fonts/NationalWeb-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'National';
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/NationalWeb-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'National';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/NationalWeb-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'National';
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/NationalWeb-Semibold.woff2') format('woff2');
}
